.App {
  background-color: #f0f0f0;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

.tooltiptext {
  width: 170px;
  background-color: rgb(255, 0, 0);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top:50px;
  right:110px;
  position: absolute;
  z-index: 10;
  opacity: 0.6;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 85%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 0, 0) transparent;
}


input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #474747;
  border-radius: 3px;
  outline: none;
}

input:focus {
  background-color: lightblue;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;
   margin-right: 25%;
   height:30px;

}

.padding-margin {
  padding: 0px 0px 5px 0px;
  margin: 0;
}

.soon {
  background-color: red;
  border-radius: 6px;
  color: white;
  padding: 0px 15px 4px 15px;
  font-size: 30px;
  margin:0px;
  z-index: 3;
  position: relative;
}

#loadingg {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 5px solid rgb(0, 165, 206);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.absolute-load {
  position:absolute;
  right:8px;
  top:15px;
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgb(0, 165, 206);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*     -----------------------------------     MENU     -----------------------------------     */


.navbar {
  background-color: rgb(0, 0, 0);
  display: block;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 1%;
  margin-right: 1%;
}

.navbar ul li {
  float: left;
}

.navbar ul li.logo {
  display: block;
  text-align: center;
  padding-top:6px;
  text-decoration: none;
  border-right: none;
  padding-right: 10px;
}

.navbar ul li.nextToLogo {
  display: block;
  text-align: center;
  padding-left: 5px;
  padding-top: 14px;
  padding-bottom: 16px;
  font-size: 20px;
  color:rgb(255, 255, 255);
  position: relative;
}

.buttonconnect {
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #000000;
  background-color:  #29C5F6;
  border: none;
  border-radius: 10px;
  float: right;
  font-weight: bold;
  margin-left:10px;
}

.buttonconnect:hover {background-color: #23a2c9; color:black;}

.buttonconnect:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.navbar ul li.logo img {
  width: 45px;
  height: 45px;
}

.navbar ul li.buttontop {
float: right;
padding-top: 12px;
border-right: none;
}

.navbar ul li.acc {
  float: right;
  padding: 17px 5px 10px 5px;
  color: #ffffff;
  overflow: hidden;
  margin-right:0;
  margin-left: 0; 
}

.navbar ul li.logo-bsc {
  float: right;
  padding: 12px 0px 0px 0px;
  color: #ffffff;
  overflow: hidden;
  margin-right:0;
  margin-left: 0; 
}

.logo-bsc img {
  width:30px;
  height:30px;
  border-radius: 50px;
  padding: 0;
  margin: 0;
}

.navbar ul li.nextToLogo a:hover {color:#29C5F6;}

.navbar ul li.nextToLogo a {
  color:rgb(255, 255, 255);
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  padding-right: 5px;
  border-right:1px solid rgb(148, 148, 148);
}

.smallnavbar {
  display: none;
}


/*     -----------------------------------     MENU END    -----------------------------------  */

/*     -----------------------------------     HOME      -----------------------------------    */

.home {
  background-color: #f0f0f0;
  height: auto;
  overflow: hidden;
  width: 100%;
}

.top-divs {
  width: 96%;
  height:500px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-image: linear-gradient(
    rgb(0, 6, 34) 25%,
    rgb(0, 0, 0) 100%
  );
}

.top-divs::after {
  content: "";
  display: table;
  clear: both;
}

.top-left-div {
  float: left;
  font-size: 40px;
  margin-left: 100px;
  margin-top: 40px;
  z-index: 6;
  position: relative;
}

.top-left-div h1{
  color:#23a2c9;
  padding:0px;
  margin: 0;
}

.top-left-div h3{
  color:#0c5b69;
  padding:0px;
  margin: 0;
}

.blue-text {
  color:#23a2c9;
  font-size: 30px;
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 5px;
}

.blue-square {
  border: 1px solid black;
  background-color: #29C5F6;
  font-size: 25px;
  font-weight: bold;
  color:white;
  padding:10px;
  border-radius: 5px;
}

.blue-square a {
  text-decoration: none;
  color:#000;
}

.blue-border {
  margin-left: 20px;
  border: 1px solid #29C5F6;
  background-color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  color:rgb(0, 0, 0);
  padding:10px;
  border-radius: 5px;
}

.blue-border a {
  text-decoration: none;
  color:#000;
}

.top-right-div {
  position: absolute;
  top: 70px;
  right:10px;
  opacity: 1;
  overflow: hidden;
  z-index: 5;
}

.backg {
  position:relative;
  height:500px;
  width:500px;
  border-radius:50%;
  transform:scale(0.9);
}

.planet {
  height:200px;
  width:200px;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(200px circle at 20% 35%, rgb(255, 255, 255) 0%, rgb(98, 160, 189) 40%, rgba(0, 0, 0, 0.4) 80%, rgb(0, 0, 0) 100%);
  top:45px;
  left:220px;
}

.planet img {
  height:200px;
  width:200px;
  border-radius:100%;
  position: relative;
  top:0px;
  left:0px;
  transform: rotate(-10deg);
  opacity: 0.5;
}

  @keyframes slide {
    0% {
      left: -100;
      top: 0;
    }
    50% {
      left: 120px;
      top: 0px;
    }
    100% {
      left: 290px;
      top: 0;
    }
  }

.astro{
  position: relative;
  left:80px;
  bottom: 400px;
  transform: rotate(-80deg);
}

.an {
  animation-name: flo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: relative;
  bottom: 80px;
  left:20px;
}

@keyframes flo{
  50%{
    transform: translateY(30px);
  }
}

.tank {
  background-color: #b6b6b6;
  height:120px;
  width:120px;
  border-radius: 10px;
  position: relative;
  left:95px;
  top:70px;
  transform: rotate(-80deg);
}

.helmet {
  background-color: white;
  height:93px;
  width:100px;
  border-radius:45%;
  position: relative;
  left:20px;
  z-index: 5;
}

.glass {
  background-color: #6b6b6b;
  height:60px;
  width:80px;
  border-top-left-radius:60%;
  border-top-right-radius:60%;
  border-bottom-left-radius:40%;
  border-bottom-right-radius:40%;
  position: relative;
  left:10px;
  top:7px;
}

.shine {
  background-color: rgba(238, 238, 238, 0.7);
  height:15px;
  width:15px;
  border-radius: 50%;
  position: relative;
  left:10px;
  top:15px;
}

.dress {
  background-color:#f2f2f2;
  height:100px;
  width: 100px;
  border-radius: 10%; 
  position: relative;
  bottom:5px;
  left:20px;
}

.handr {
  height: 26px;
  width:75px;
  background-color: #f2f2f2;
  border-radius:40px;
  position: relative;
  bottom:138px;
  left:95px;
  transform: rotate(-50deg);
}

.handl {
  height: 26px;
  width:75px;
  background-color: #f2f2f2;
  border-radius:40px;
  position: relative;
  bottom:111px;
  right:29px;
  transform: rotate(50deg);
}

.handr1 {
  height: 26px;
  width:57px;
  background-color: #f2f2f2;
  border-radius:26px;
  position: relative;
  bottom: 18px;
  left:35px;
  transform: rotate(90deg);
}

.handl1 {
  height: 26px;
  width:57px;
  background-color: #f2f2f2;
  border-radius:26px;
  position: relative;
  bottom: 17px;
  right:17px;
  transform: rotate(-90deg);
}

.glover {
  height:28px;
  width:26px;
  background-color: white;
  border-top-left-radius:50%; 
  border-top-right-radius:50%;
  transform: rotate(-90deg);
  position: relative;
  bottom: 1px;
  right:16px;
}

.glovel {
  height:28px;
  width:26px;
  background-color: white;
  border-top-left-radius:50%; 
  border-top-right-radius:50%;
  transform: rotate(90deg);
  position: relative;
  bottom: 1px;
  left:42px;
}

.thumbr {
  height: 10px;
  width:10px;
  border-radius: 50%;
  background-color: white;
  position: relative;
  right:7px;
  top:19px;
}

.thumbl {
  height: 10px;
  width:10px;
  border-radius: 50%;
  background-color: white;
  position: relative;
  left:21px;
  top:19px;
}

.b1 {
  background-color: tomato;
  width:28px;
  height:5.5px;
  border-radius:13px;
  position: relative;
  top:18px;
  right: 1px;
}

.b2 {
  background-color: tomato;
  width:28px;
  height:5.5px;
  border-radius:13px;
  position: relative;
  top:18px;
  right: 1px;
}

.c {
  background-color: white;
  width:55px;
  height:30px;
  border-radius:8px;
  position: relative;
  top:25px;
  left:23px;
}

.btn1 {
  height:12px;
  width: 12px;
  border-radius: 50%;
  background-color: #00c3ff;
  position: relative;
  left:5px;
  top:10px;
}

.btn2 {
  height:12px;
  width: 12px;
  border-radius: 50%;
  background-color: #ffd147;
  position: relative;
  left:21px;
  bottom:2px;
}

.btn3 {
  height:12px;
  width: 12px;
  border-radius: 50%;
  background-color: #00c3ff;
  position: relative;
  bottom:14px;
  left:38px;
}

.btn4 {
  height:20px;
  width:20px;
  border-radius: 50%;
  background-color: #a6a6a6;
  position: relative;
  left:19px;
  top:4px;
}

.legl {
  height:100px;
  width:40px;
  background-color: #f2f2f2;
  position: relative;
  bottom: 68px;
  left:5px;
  transform: rotate(20deg);
}

.legr {
  height:100px;
  width:40px;
  background-color: #f2f2f2;
  position: relative;
  bottom: 168px;
  left:96px;
  transform: rotate(-20deg);
}

.bootl1 {
  background-color: white;
  width: 43px;
  height:35px;
  border-top-left-radius: 50%;
  border-top-right-radius:50%;
  position: relative;
  top:65px;
  right:1.5px;
}

.bootr1 {
  background-color: white;
  width: 43px;
  height:35px;
  border-top-left-radius: 50%;
  border-top-right-radius:50%;
  position: relative;
  top:65px;
  right:1.5px;
}

.bootl2 {
  background-color: tomato;
  width:45px;
  height: 5px;
  border-radius:21px;
  position: relative;
  top:30px;
  right: 1.5px;
}

.bootr2 {
  background-color: tomato;
  width:45px;
  height: 5px;
  border-radius:21px;
  position: relative;
  top:30px;
  right: 1.5px;
}

.pipe {
  background-color:  transparent;
  height:80px;
  width:80px;
  border:10px solid #868686;
  border-radius:40px 0px 0px 70px;
  border-right: none;
  transform: rotate(180deg);
  position: relative;
  bottom: 330px;
  left:130px;
}

.pipe2 {
  background-color:  transparent;
  height:90px;
  width:42px;
  border:10px solid #868686;
  border-radius:40px 0px 0px 0px;
  border-right: none;
  transform: rotate(90deg);
  position: relative;
  border-bottom: none;
  left:67px;
  bottom:34px;
}

.pipe3 {
  height:10px;
  width: 10px;
  background-color: #868686;
  position: relative;
  border-radius: 65%;
  bottom:10px;
  left:37px;
}

.s1,.s2,.s3,.s4,.s5,.s6 {
  background-color: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
}

.s1 {
  bottom:150px;
  left:200px;
}

.s2 {
  top:130px;
  left:254px;
}

.s3 {
  bottom:98px;
  left:65px;
}

.s4 {
  top: 216px;
  left:249px;
}

.s5 {
  top: 139px;
  left:100px;
}

.s6 {
  top:60px;
  left:370px;
}

@keyframes animateBg {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}

.cards-div {
  overflow: hidden;
}

.card-div span {
  font-size: 50%;
}

.squares {
  color:#0FBFDE;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.squares li {
  list-style-type: none;
  width: 250px;
  height: 150px;
  box-shadow:10px 10px 8px #888888;
    background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1),
		rgb(0, 89, 124),
		rgb(0, 119, 167),
		rgba(0, 119, 167, 1)
	);
	animation: animateBg 6s ease infinite alternate;
	background-size: 100% 2000%;
  text-align: center;
  padding-top:5px;
  margin-left: 2.5%;
  margin-right: 3%;
}

.squares li:nth-child(4) {
  margin-right: 6%;
}

.centerd {
  text-align: center;
}

#centerd-l-h1 {
  color: #0c5b69;
  font-size: 50px;
  font-weight: bolder;
}

#centerd-l-p {
  color:#23a2c9;
  font-size: 30px;
}

#centerd-s-h1 {
  display: none;
  color: #0c5b69;
  font-weight: bolder;
}

#centerd-s-p {
  display: none;
  color:#23a2c9;
}

@keyframes unlock-circle {
  0% {
      bottom: 200px;
  }
  25% {
      bottom: 200px;
  }
  50% {
      bottom: 150px;
  }
  75% {
      bottom: 150px;
  }
  100% {
      bottom: 200px;
  }
}

@keyframes unlock-box {
  0% {
      bottom: 280px;
  }
  25% {
      bottom: 280px;
  }
  50% {
      bottom: 230px;
  }
  75% {
      bottom: 230px;
  }
  100% {
      bottom: 280px;
  }
}

.wrapper {
  height: 300px;
  width: 300px;
  border-radius: 100%;
  margin: 50px auto;
  background-color: rgb(0, 0, 0);
}

.base {
  background-color: #ecf0f1;
  width: 200px;
  height: 170px;
  border-radius: 30px;
  margin: 0 auto;
  position: relative;
  top: 100px;
  z-index: 6;
}

.base-bottom {
  background-color: #bdc3c7;
  width: 200px;
  height: 85px;
  border-radius: 0 0 30px 30px;
  top: 85px;
  position: relative;
  opacity: 0.4;
}

.lock-cirlce {
  height: 180px;
  width: 110px;
  border-radius: 45px;
  z-index: 5;
  background-color: #bdc3c7;
  position: relative;
  margin: 0 auto;
  bottom: 150px;
  animation-name: unlock-circle;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

.lock-circle-inside {
  height: 180px;
  width: 50px;
  border-radius: 30px;
  z-index: 5;
  background-color: rgb(0, 0, 0);
  position: relative;
  margin: 0 auto;
  top: 25px;
}

.lock-box {
  animation-name: unlock-box;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  position: relative;
  height: 50px;
  width: 50px;
  background-color: rgb(0, 0, 0);
  bottom: 230px;
  left: 170px;
  z-index: 5;
}

.lock-inside-top {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgb(0, 0, 0);
  z-index: 4;
  position: relative;
  bottom: 45px;
  left: 75px;
}

.lock-inside-bottom {
  width: 30px;
  height: 80px;
  background-color: rgb(0, 0, 0);
  z-index: 4;
  position: relative;
  bottom: 85px;
  left: 85px;
}

.box-in-center {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: auto;
}

.box-in-center img.check-s {
  display: none;
}

.box-in-center img.check-l {
  width:900px;
  height: 500px;
}

.divs {
  background:#000 url(./images/stars.png) repeat top center;
  width: 96%;
  min-height: 250px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color:#29C5F6;
  margin-left: 2%;
  margin-right: 2%;
  position: relative;
}

.divs:after {
  content: "";
  display: table;
  clear: both;
}

.live {
  background-color: #3E9C35;
  border-radius: 6px;
  color: white;
  padding: 0px 15px;
  font-size: 30px;
  margin:0px;
  z-index: 3;
  position: relative;
}

.divs .p-left {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.bluebutton {
  padding: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #ffffff;
  background-color:  #29C5F6;
  border: none;
  border-radius: 10px;
  float: left;  
  
}

.divs-right-img {
  position: absolute;
  top: 25px;
  right:10px;
  opacity: 1;
  overflow: hidden;
  z-index: 0;
}

.circle-img {
  border-radius: 100%;
  height: 200px;
  width: 200px;
  padding: 20px;
}

.divs-right-write {
  float: right;
  z-index: 1;
}

.divs-right-write .align-right {
  text-align: right;
}

.divs .p-right {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}

.bluebutton-right {
  padding: 5px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  outline: none;
  color: #ffffff;
  background-color:  #29C5F6;
  border: none;
  border-radius: 10px;
  float: right;  
}

.divs-left-img {
  position: absolute;
  top: 25px;
  left:10px;
  opacity: 1;
  overflow: hidden;
  z-index: 0;
}

.divs-left-write {
  float: left;
  z-index: 1;
}


/*     -----------------------------------     HOME END   -----------------------------------   */

/*     -----------------------------------     PRESALE      -----------------------------------    */

.App-header {
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #0c5b69;
  font-family: Tahoma, Verdana, sans-serif;
}

* {
  box-sizing: border-box;
}

.row {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.leftcolumn {   
  float: left;
  width: 15.5%;
  height:100%;
  text-align: center;
  margin-right: 0.5%;
  overflow: hidden;
  display: table-column;
}

.upperleft h2 {
  font-size: 38px;
  font-weight: normal;
  background-color: #EEEEEE;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 10%;
  overflow: hidden;
  font-weight: bolder;
}

.upperleft p {
  padding: 5px 10px;
  margin-top: 0.5%;
  font-size: 12px;
  text-align: left;
  background-color: #EEEEEE;
  height: 9.5%;
  overflow: hidden;
  margin-bottom: 0px;
}

.upperleft .logolist {
  background-color: #EEEEEE;
  overflow: hidden;
  padding: 0px 0px;
  height: 9.5%;
  margin-top: 0.5%;
  text-align: center;
}

.upperleft .logolist ul {
 list-style-type: none;
 padding: 10px 0px;
 overflow: hidden;
 margin-top: 0%;
 margin-bottom: 0%;
}

.upperleft .logolist .a{
  text-decoration: none;
  color:#23a2c9;
  font-size: 18px;
}

.upperleft .logolist ul li {
  float: none;
}

.upperleft .logolist ul li.logos {
  display: inline-block;
  float: none;
  text-decoration: none;
}

.upperleft .logolist ul li.logos a img {
  width: 30px;
  height: 30px;
}

.buy {
  background-color: #EEEEEE;
  margin-top: 0.5%;
  text-align: left;
  height: 34.5%;
  overflow: hidden;
}

.tinytext {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 10px;
}

.floatleft {
  float: left;
}

.floatright {
  float:right;
}

.pdiv {
  padding: 5px 35px 0px 10px;
  
}

.formdiv {
  padding: 5px 10px;
  background-color: #EEEEEE;
  font-size: 14px;
  margin-top: 0px;
}

.relative {
  position: relative;
}

.absolute {
  position:absolute;
  right:8px;
  top:18px;
}

.absolute:hover {
  cursor: pointer;
}

.buttonsend {
  display: inline-block;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #979797;
  background-color: #d1d1d1;
  border: 1px solid #474747;
  border-radius: 3px;
}

.buttonsend:hover {background-color: #23a2c9; color:black;}

.buttonsend:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

ul.underbuy {
  padding: 2px 5px;
  position: relative;
  overflow: hidden;
  margin-top:0px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 0px;
}

.bluetext {
  color:#26b0da;
}

.bluetext a {
  text-decoration: none;
  color:#26b0da;
}

.balance {
  font-size: smaller;
}

.leftcolumn .percentages {
  margin-top: 0.5%; 
  background-color: #EEEEEE;
  margin-bottom: 0px;
  height: 34.5%;
  overflow: hidden;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 10px;
  padding-right: 10px;
}

ul.percentages {
  list-style-type: none;
  margin-bottom: 0px;
}

ul.percentages li.alignLeft {
  text-align: left;
  font-size: 16px;
}

ul.percentages li.percentDiv {
  width:92%; 
}

.tokenomics {
  font-size: 25px;
}

.buttonask {
  display: inline-block;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #474747;
  background-color: #EEEEEE;
  border: 1px solid #474747;
  border-radius: 3px;
  margin-top: 20px;
}

.buttonask:hover {background-color: #23a2c9; color:black;}

.buttonask:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.rightcolumn {
  float: left;
  width: 84%;
  background-color: #EEEEEE;
  overflow: hidden;
  display: table-column;
  padding-bottom: 0px;
}

.paragraph {
  padding: 0px 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 30%;
}

.rightcolumn .paragraph h1 {
  padding: 0;
  font-size: 40px;
  font-weight: 900;
  margin: 0px;
}

.paragraph p {
  line-height: 18px;
  font-size: 16px;
  margin-top:0px;
  margin-bottom: 0px;
}

.ended {
  background-color: red;
  border-radius: 6px;
  color: white;
  padding: 0px 15px;
  font-size: 30px;
  margin:0px;
}


#table {
  width: 100%;
  margin-top: 0px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  height: 70%;
  margin-bottom: 0px;

}

#table p {
  font-weight: bold;
  font-size: 15px;
  margin-top: 0px;
}

#table hr {
  font-weight: bold;
  font-size: 15px;
  margin-top: 0px;
}

#table p .lastone {
  border-bottom: none;
  padding-bottom: 5px;
}

#table p .righttd {
  color:#26b0da;
  text-align: right;
  font-weight: normal;
  float: right;
  margin: 0px;
  padding-top:0px;
}

#table p .righttd a {
  text-decoration: none;
  color:#26b0da;
}

#table2 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  text-align: center;
  display: none;
  table-layout:fixed;
}


/*      -----------------------------------    PRESALE END   -----------------------------------   */

/*     -----------------------------------     TIMELOCK      -----------------------------------    */

.time-backg {
  height: 1600px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

.stars-s, .twinkling-s {
position:absolute;
width:100%;
height:1600px;
display:block;
}

.stars-s {
background:#000 url(./images/stars.png) repeat top center;
z-index:0;
}

.twinkling-s{
background:transparent url(./images/twinkling.png) repeat top center;
z-index:1;

-moz-animation:move-twink-back 200s linear infinite;
-ms-animation:move-twink-back 200s linear infinite;
-o-animation:move-twink-back 200s linear infinite;
-webkit-animation:move-twink-back 200s linear infinite;
animation:move-twink-back 200s linear infinite;
}

.top-margin-10 {
  margin-top: 10px;
}

.h1-glow {
  color:#00c3ff;
  text-shadow: 0 5px #002c3a;
  text-align: center;
  z-index: 2;
  position: relative;
  padding: 0;
  margin: 0;
}

.create-lock {
  background-color:rgb(153, 153, 153,0.2);
  max-width: 600px;
  min-width: 300px;
  height:700px;
  padding: 20px;
  margin: 40px auto;
  color:#29C5F6;
  text-align: center;
  z-index: 2;
  position: relative;
}

.create-lock span {
  color:rgb(255, 106, 106);
}

.create-lock ul {
  text-align: left;
  margin: 5px;
}

.left-bold {
  text-align: left;
  font-weight: 800;
  font-size: 120%;
  padding:0;
  margin: 0;
}

.factory-a {
  padding: 5px 0px 5px 0px;
  color:#29C5F6;
  margin: 0;
}

.label {
  text-align: left;
  padding: 0;
  margin: 0;
}

.contracts-container {
  background-color:rgb(153, 153, 153,0.2);
  max-width: 600px;
  min-width: 300px;
  height:auto;
  padding: 10px;
  margin: 10px auto;
  color:#29C5F6;
  position: relative;
  text-align: center;
  z-index: 2;
}

.dots-container {
  width: 140px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.pulse-dot {
  width: 22px;
  height: 22px;
  border-radius: 25px;
  background-color: #00c3ff;
}

.pulse-dot-1 {
  opacity: 0.5;
  animation: pulse 1.5s ease infinite;
}

.pulse-dot-2 {
  animation: pulse 1.5s ease infinite;
  animation-delay: 0.5s;
}

.pulse-dot-3 {
  animation: pulse 1.5s ease infinite;
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.contracts {
  margin:20px;
  background-color:rgba(0,0,0, 0.6);
  box-shadow:0px 0px 10px #0c5b69;
  text-align: left;
  padding: 10px;
}

.contracts a {
  color:#23a2c9;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.contracts h3, h4 {
  padding:0;
  margin:0;
}

.btn-contract-container {
  text-align: right;
}

.btn-contract {
  margin-top:10px;
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #000000;
  background-color: #23a2c9;
  border: 1px solid #000000;
  border-radius: 3px;
  font-weight: 700;
  margin-right: 5px;
}

.btn-contract:hover {background-color: #1a7b99; color:black;}

.btn-contract:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px rgb(0, 0, 0);
  transform: translateY(4px);
}


/*     -----------------------------------     TIMELOCK END   -----------------------------------   */

/*     -----------------------------------     POPUPS     -----------------------------------     */


.popup {
  display: none;
}

.popup_inner {
  display: none;
}


.infopopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  overflow-y: auto;
  z-index: 10;
}

.infopopup_inner {
  position: absolute;
  left: 35%;
  right: 35%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  background: white;
  color:black;
  display: block;
  object-fit:contain;
  align-items: center;
}


.closeinfo {
  padding: 0px;
  float: right;
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-right: 0px;
}

.info {
  font-size: 16px;
}

.zeroPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  overflow-y:  auto;
  z-index: 10;
}

.zeropopup_inner {
  position: relative;
  max-width: 500px;
  max-height: 400px;
  min-width: 320px;
  min-height: 500px;
  margin: auto;
  top:25%;
  background: #EEEEEE;
  color:#000000;
  display: block;
  object-fit:contain;
  align-items: center;
  border-radius: 30px;
  opacity: 0.9;
}

.zeropopup_inner p {
  padding: 20px 10px;
}

.closezero {
  padding: 0px;
  float: right;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}

.transfer-form {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.transfer-form p {
  padding:0;
  margin:0;
}

.red {
  color:red;
}

.input-pop {
  border-radius: 5px;
  width: 200px;
  margin-left: 20px;
  border:2px solid #00c3ff;
}

.btn-transfer {
  display: inline-block;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #000000;
  background-color: #23a2c9;
  border: 1px solid #000000;
  border-radius: 3px;
  font-weight: 700;
  margin-right: 5px;
}

.btn-transfer:hover {background-color: #1a7b99; color:black;}

.btn-transfer:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px rgb(0, 0, 0);
  transform: translateY(4px);
}

.time-form {
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.time-form p {
  padding:0;
  margin:0;
}

.btn-release {
  display: inline-block;
  position: absolute;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #000000;
  background-color: #23a2c9;
  border: 1px solid #000000;
  border-radius: 3px;
  font-weight: 700;
  bottom: 10px;
  left: 20px;
}

.btn-release:hover {background-color: #1a7b99; color:black;}

.btn-release:active {
  background-color: #0FBFDE;
  box-shadow: 0 5px rgb(0, 0, 0);
  transform: translateY(4px);
}

.logoZero {
  position: absolute;
  padding: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  bottom: 5px;
  right: 5px;
  opacity: 0.5;
}

.alert-link {
  text-decoration: none;
}

/*     -----------------------------------     POPUPS END   -----------------------------------   */

/*     -----------------------------------     FOOTER      -----------------------------------    */


.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  text-align: center;
  padding: 0px;
  z-index: 2;
}

.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.footer ul li {
  float: left;
  padding: 5px;
  font-size: 20px;
}

.footer ul li.footerlogo {
  float: right;
}

.footer ul li.footerlogo img {
  width: 30px;
  height: 30px;
}

.footer ul li.footerlogo img:hover {opacity: 0.7}

/*     -----------------------------------     FOOTER END   -----------------------------------   */




/*     -----------------------------------     MEDIA QUERY     -----------------------------------    */

@media only screen and (max-width: 2795px) {
  .leftcolumn .percentages {
    padding-bottom:20px;
    padding-top: 3px;
  }

  .rightcolumn {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 2779px) {
  .leftcolumn .percentages {
    padding-bottom:4px;
  }
}

@media only screen and (max-width: 2692px) {
  .leftcolumn .percentages {
    padding-bottom:20px;
  }

}

@media only screen and (max-width: 2532px) {
  .leftcolumn .percentages {
    padding-bottom:16px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 1908px) {
  .rightcolumn {
    padding-bottom: 11px;
  }
}

@media only screen and (max-width: 1855px) {
  .rightcolumn {
    width: 80%;
    padding-bottom: 0px;
  }

  .leftcolumn {
    width: 19.5%;
  }

  .leftcolumn .percentages {
    padding-bottom:30px;
    padding-top: 15px;
   
  }
}

@media only screen and (max-width: 1784px) {
  .leftcolumn .percentages {
    padding-bottom:25px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1516px) {
  .leftcolumn .percentages {
    padding-bottom:17px;
    padding-top: 15px;
  }

  .tooltiptext {
    right:105px;
  }
}

@media only screen and (max-width: 1475px) {
  .leftcolumn .percentages {
    padding-bottom:20px;
    padding-top: 17px;
  }

  .buttonask {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1466px) {
  .leftcolumn .percentages {
    padding-bottom:36px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1378px) {
  .leftcolumn .percentages {
    padding-bottom:30px;
    padding-top: 26px;
  }
}

@media only screen and (max-width: 1206px) {
  .divs .p-left {
    font-size: 15px;
  }

  .divs .p-right {
    font-size: 15px;
  }

  .rightcolumn {
    width: 70%;
  }

  .leftcolumn {
    width: 29.5%;
  }

  .leftcolumn .percentages {
    padding-bottom:40px;
    padding-top: 40px;
  }

  .buttonask {
    font-size: 20px;
  }

  .card-div span {
    font-size: 45%;
  }

  .squares {
    font-size: 30px;
  }

  .squares li {
    padding-top:20px;
  }
}

@media only screen and (max-width: 1137px) {
  .leftcolumn .percentages {
    padding-bottom:50px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 1079px) {

  .top-right-div {
    opacity: 0.2;  
  }
}

@media only screen and (max-width: 1002px) {
  .leftcolumn .percentages {
    padding-bottom:45px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 975px) {


  .box-in-center img.check-l {
    width: 700px;
    height: 400px;
  }
}
/*     -----------------------------------     MEDIA QUERY  910   -----------------------------------    */

@media only screen and (max-width: 910px) {
  #centerd-l-h1 {
    font-size: 40px;
  }
  
  #centerd-l-p {
    font-size: 20px;
  }

  .divs .p-left {
    font-size: 16px;
  }

  .divs .p-right {
    font-size: 16px;
  }

  .divs-left-img {
    opacity: 0.4;
  }

  .divs-right-img {
    opacity: 0.4;
  }

  .card-div span {
    font-size: 40%;
  }

  .squares li {
    margin-left: 1%;
    margin-right: 3%;
  }

  .upperleft .logolist ul {
   padding-left: 1%;
   padding-right: 1%;
  }

  .popup_inner hr {
    margin: 0px;
  }

  .popup {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    overflow-y: auto;
    z-index: 10;
  }

  .popup_inner {
    display: block;
    position: absolute;
    left: 5px;
    right: 400px;
    top: 55px;
    max-height: 550px;
    margin: auto;
    background: white;
    color:black;
    display: block;
    object-fit:contain;
    align-items: center;
    overflow: hidden;
    font-size: 30px;
    text-align: center;
    overflow: scroll;
  }
  
  .popup_inner .list {
    margin-top: 20px;
    text-align: center;
    left:20px;
    right:20px;
    padding-bottom: 20px;
  }

  .popup_inner .list a {
    text-decoration: none;
    color:#000000;
    display: block;
    position: relative;
    margin: 0px;
  }

  .popup_inner .list a:hover {
    color:#23a2c9;
  }

  .rightcolumn {
    padding-bottom: 0px;
    width: 90%;
    margin-right:5%;
    margin-left: 5%;
  }

  .leftcolumn {
    width: 90%;
    margin-right: 0%;
    margin-right:5%;
    margin-left: 5%;
  }

  .leftcolumn .percentages {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .infopopup_inner {
    overflow-y: auto;
    left: 5%;
    right: 5%;
    top: 20%;
    bottom: 20%;
  }
}
/*     -----------------------------------     MEDIA QUERY  760   -----------------------------------    */

@media only screen and (max-width: 760px) {

  .box-in-center img.check-l {
    width: 600px;
    height: 370px;
  }

  .top-right-div {
    top: 30px;
    right:10px;
  }

  .top-divs {
    height: 400px;
  }

  .top-left-div {
    font-size: 30px;
  }
  
  .blue-text {
    font-size: 20px;
  }

  .card-div span {
    font-size: 50%;
  }

  .squares {
    font-size: 20px;
  }

  .squares li {
    height: 120px;
  }
}
/*     -----------------------------------     MEDIA QUERY   600  -----------------------------------    */

@media only screen and (max-width: 600px) {

  .divs .p-left {
    font-size: 17px;
  }

  .divs .p-right {
    font-size: 17px;
  }

  .tooltiptext {
    right:80px;
  }
  
  #centerd-l-h1 {
    font-size: 30px;
  }
  
  #centerd-l-p {
    font-size: 15px;
  }

  .zeropopup_inner {
    top:10%;
    padding:5px;
  }
 
  .transfer-form p {
    padding:0px 10px;
  }

  .contracts-container {
    padding: 20px;
    margin: 40px auto;
  }

  .contracts {
    margin:10px;
  }

  .contracts h3, h4 {
    font-size: 90%;
  }
  
  .btn-contract {
    font-size: 15px;
    font-weight: 600;
  }

  .navbar {
    display: none;
  }

  .smallnavbar {
    background-color: #000000;
    display: block;
  }
  
  .smallnavbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .smallnavbar ul li {
    float: left;
  }
  
  .smallnavbar ul li .logo {
    display: block;
    text-align: center;
    padding-top:6px;
    text-decoration: none;
    border-right: none;
    padding-right: 10px;
  }

  .smallnavbar ul li.logo img {
    width: 45px;
    height: 45px;
    margin-top: 5px;
  }

  .smallnavbar .burger {
    width: 35px;
    height: 35px;
    margin-top: 8px;
    margin-left:10px;
  }

  .smallnavbar .burger .menu-icon {
    width: 35px;
    height: 5px;
    background-color:#29C5F6;
    margin: 6px 0;
    border-radius: 5px;
  }

  .smallnavbar .burger:hover {
    opacity: 0.7;
  }

  .smallnavbar ul li.buttontop {
    float: right;
    padding-top: 12px;
    border-right: none;
  }
  
  .smallnavbar ul li.acc {
    float: right;
    padding: 15px 5px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    margin-right:0;
    margin-left: 0;
  }

  .smallnavbar ul li.logo-bsc {
    float: right;
    padding: 12px 0px 0px 0px;
    color: #ffffff;
    overflow: hidden;
    margin-right:0;
    margin-left: 0; 
  }
  
  .divs h1 {
    font-size: 20px;
  }

  .box-in-center img.check-l {
    width: 500px;
    height: 300px;
  }

  .squares {
    width: 96%;
    margin-left: 1%;
    margin-right: 3%;
    font-size: 20px;
    display: inline-block;
  }

  .squares li {
    width:35%;
    padding-top:20px;
    margin-left: 6%;
    margin-right: 4%;
    display: inline-block;
    margin-top:10px;
  }

  .top-right-div {
    top:110px;
    right:70px;
  }

  .backg {
    height:300px;
    width:300px;
  }

  .planet {
    height:90px;
    width:90px;
  }

  .planet img {
    height:90px;
    width:90px;
  }

  .astro {
    bottom: 270px;
  }

  .tank {
    height:100px;
    width:105px;
    left:60px;
    top:55px;
  }

  .helmet {
    height:63px;
    width:70px;
  }

  .glass {
    height:40px;
    width:60px;
    left:5px;
  }

  .shine {
    height:10px;
    width:10px;
    top:10px;
  }

  .dress {
    height:80px;
    width: 80px;
    left:10px;
  }

  .handr {
    height: 20px;
    width:55px;
    bottom:118px;
    left:65px;
    transform: rotate(-60deg);
  }

  .handl {
    height: 20px;
    width:55px;
    bottom:100px;
    right:19px;
    transform: rotate(60deg);
  }

  .handr1 {
    height: 20px;
    width:45px;
    bottom: 14px;
    left:30px;
  }

  .handl1 {
    height: 20px;
    width:45px;
  }

  .c {
    background-color: rgb(255, 255, 255);
    width:40px;
    top:20px;
    left:20px;
  }

  .btn1 {
    height:10px;
    width: 10px;
    left:4px;
  }
  .btn2 {
    height:10px;
    width: 10px;
    left:16px;
    bottom:0px;
  }

  .btn3 {
    height:10px;
    width: 10px;
    bottom:10px;
    left:28px;
  }

  .btn4 {
    height:15px;
    width:15px;
    left:15px;
    top:-8px;
  }

  .legl {
    height:80px;
    width:30px;
  }

  .legr {
    height:80px;
    width:30px;
    bottom: 140px;
    left:70px;
  }

  .bootl1 {
    width: 33px;
    height:25px;
  }

  .bootr1 {
    width: 33px;
    height:25px;
  }

  .bootl2 {
    width:35px;
    top:20px;
  }

  .bootr2 {
    width:35px;
    top:20px;
  }

  .pipe {
    height:50px;
    width:40px;
    bottom: 255px;
    left:95px;
  }

  .pipe2 {
    height:50px;
    width:32px;
    left:35px;
    bottom:19px;
  }

  .pipe3 {
    height:0px;
    width: 0px;
  }

  .top-divs {
    height: 370px;
  }

  .top-left-div {
    margin-left: 5%;
  }
  
  .blue-square {
    font-size: 20px;
  }

  .blue-border {
    font-size: 20px;
  }

  .infopopup_inner {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
  }

  .live {
    padding: 0px 15px 2px 15px;
    font-size: 20px;
  }

  .soon {
    padding: 0px 10px 2px 10px;
    font-size: 20px;
  }

  .ended {
    padding: 0px 15px 2px 15px;
    font-size: 20px;
  }

  .rightcolumn .paragraph h1 {
    font-size: 25px;
    padding-top: 5px;
    padding-bottom: 5px; 
  }

  .popup_inner {
    right: 130px;
    top: 54px;
    max-height: 470px;
    font-size: 25px;
  }

  .popup_inner .list {
    margin-top: 30px;
  }

  .popup_inner .list p {
    margin-bottom: 20px;
    line-height: 25px;
  }

  .App-header {
   width: 100%;
   margin: 0 auto;
   overflow: auto;
  }

  .smallnavbar ul .logo img {
    width: 35px;
    height: 35px;
    margin-left:10px;
    margin-top:4px;
  }

  .leftcolumn {   
    float: left;
    width: 98%;
    padding-right: 0px;
    margin-left: 1%;
    margin-right: 1%;
  }

  .rightcolumn {
    float: left;
    width: 98%;
    padding-left: 0px;
    border-left:none;
    padding: 0;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 5px;
  }

  #table {
    display: none;
  }

  #table2 {
    visibility: visible;
    overflow-x: auto;
    display: block;
    padding-bottom: 0px;
    text-align: left;
    font-size: 16px;
    word-wrap: break-word;
    background-color: #EEEEEE;
    width: 100%;
    margin-top: 5px;
    padding: 7px;
  }

  .footer ul li {
    float: none;
  }

  .footer ul li.footerlogo {
    float: none;
    display:inline-block;
  }

  .footer ul li.footerlogo img {
    width: 30px;
    height: 30px;
  }

  .buttonconnect {
    padding: 6px;
    font-size: 16px;
    border-radius: 15px;
  }
}
/*     -----------------------------------     MEDIA QUERY  450   -----------------------------------    */

@media only screen and (max-width: 450px) {

  .address {
    font-size: 85%;
  }

  #centerd-l-h1 {
    display: none;
  }
  
  #centerd-l-p {
    display: none;
  }

  #centerd-s-h1 {
    display: block;
    font-size: 25px;
  }

  #centerd-s-p {
    display: block;
    font-size: 16px;
  }

  .time-backg {
    height: 1700px;
  }
  
  .stars-s, .twinkling-s {
    height:1700px;
  }

  .contracts {
    margin-top:10px;
  }
  
  .contracts a {
    font-size: 90%;
  }
  
  .contracts h3, h4 {
    font-size: 80%;
  }

  .contracts p {
    font-size: 70%;
  }
  
  .btn-contract {
    font-size: 14px;
  }

  .box-in-center img.check-l {
    display:none;
  }

  .box-in-center {
    width:95%;
  }

  .box-in-center img.check-s {
    display:flex;
    width:100%;
    min-width:310px;
    height: 200px;
  }

  .top-right-div {
    right:40px;
  }

  .top-left-div {
    font-size: 20px;
  }
 
  .blue-square {
    font-size: 15px;
  }

  .blue-border {
    font-size: 15px;
  }
}
/*     -----------------------------------     MEDIA QUERY  370   -----------------------------------    */

@media only screen and (max-width: 370px) {

  .rightcolumn .paragraph h1 {
    font-size: 21px;
    padding-top: 5px;
    padding-bottom: 5px; 
  }

  .paragraph p {
    line-height: 18px;
    font-size: 14px;
    margin-top:0px;
    margin-bottom: 0px;
  }

  .tooltiptext {
    right:65px;
  }

  .smallnavbar .burger .menu-icon {
    width: 30px;
    height: 5px;
    background-color:#29C5F6;
    margin: 6px 0;
    border-radius: 5px;
  }

  .buttonconnect {
    padding: 5px;
    font-size: 15px;
    border-radius: 15px;
    margin-left:0px;
  }

  .smallnavbar ul li.acc {
    float: right;
    padding: 16px 5px 10px 5px;
    font-size: 14px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    margin-right:0;
    margin-left: 0;
  }

  .divs h1 {
    font-size: 16px;
  }

  .live {
    font-size: 16px;

  }

  .soon {
    font-size: 16px;

  }

  .zeropopup_inner {
    min-height: 500px;
    top:5%;
  }

  .input-pop {
    width: 150px;
  }

  .btn-transfer {
    padding: 10px 5px;
  }

  .transfer-form p {
    padding:0;
  }

  .left-bold {
    font-weight: 700;
    font-size: 110%;
  }

  .cards-div {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .card-div span {
    font-size: 65%;
  }

  .squares li {
    width:70%;
    min-width: 90px;
    height: 120px;
    padding-top:30px;
    margin-left: 8%;
    margin-right: 8%;  
  }

  .top-right-div {
    margin-right: 0px;
  }
}